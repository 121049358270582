/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiTextField: Components<Omit<Theme, 'components'>>['MuiTextField'] = {
  defaultProps: {
    variant: 'filled',
  },
};

export const MuiOutlinedInput: Components<Omit<Theme, 'components'>>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      borderRadius: '0.5rem',
    },
  },
};

export const MuiFilledInput: Components<Omit<Theme, 'components'>>['MuiFilledInput'] = {
  styleOverrides: {
    root: {
      backgroundColor: 'var(--color-surf)',
      '::before': {
        borderColor: 'var(--color-on-surf-variant)',
      },
      ':hover': {
        backgroundColor: 'var(--color-surf-container-highest)',
      },
      ':focus': {
        backgroundColor: 'var(--color-surf-container-highest)',
      },
      '&.Mui-disabled': {
        pointerEvents: 'none',
        '::before': {
          borderBottomStyle: 'solid',
        },
      },
    },
    input: {
      '&.Mui-disabled': {
        color: 'var(--color-outline)',
        WebkitTextFillColor: 'var(--color-outline)',
      },
    },
  },
};

export const MuiFormHelperText: Components<Omit<Theme, 'components'>>['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      minHeight: '1.5rem',
      maxHeight: '1.5rem',
      lineHeight: '1.5',
    },
  },
};

export const MuiFormLabel: Components<Omit<Theme, 'components'>>['MuiFormLabel'] = {
  styleOverrides: {
    root: {
      color: 'var(--color-on-surf-variant) !important',
    },
  },
};
